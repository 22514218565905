<template>
  <div>
    <v-container>
      <page-title title="Department">
        <template slot="action">
          <v-btn class="mx-1" @click="$router.go(-1)">Back</v-btn>
          <v-btn class="mr-n1 mx-1" @click="saveData()" color="primary">Save</v-btn>
        </template>
      </page-title>

      <card-expansion title="General">
        <v-form v-model="formValid" lazy-validation ref="form1">
          <v-row>
            <v-col cols="12" md="6" lg="6">
              <v-text-field v-model="formData.dept_code" :rules="formRules.dept_code" class="" label="Dept Code" placeholder="Dept Code" outlined dense></v-text-field>
            </v-col>
            <v-col cols="12" md="6" lg="6">
              <v-text-field v-model="formData.dept_name" :rules="formRules.dept_name" class="" label="Dept Name" placeholder="Dept Name" outlined dense></v-text-field>
            </v-col>
          </v-row>
        </v-form>
      </card-expansion>
    </v-container>
  </div>
</template>

<script>
export default {
  components: {

  },
  data() {
    return {
      formValid: null,
      formData: {
        id: null,
        dept_code: null,
        dept_name: null,
      },
      formRules: {
        dept_code: [v => !!v || 'Dept Code is required'],
        dept_name: [v => !!v || 'Dept Name is required'],
      },
      options: {
        status: [
          {
            text: 'Active',
            value: true,
          },
          {
            text: 'In Active',
            value: false,
          }
        ]
      },
    }
  },

  methods: {

    async getDetail(refresh = false) {
      if (refresh == true) this.AxiosStorageRemove("GET", "department/detail");

      this.loading = true;
      this.notFound = false;
      this.showLoadingOverlay(true);

      var config = {
        params: {
          id: this.formData.id,
        },
      };
      await this.$axios
        .get("department/detail", config)
        .then((res) => {
          const resData = res.data.data;

          this.formData.id = resData.department.id;
          this.formData.dept_code = resData.department.dept_code;
          this.formData.dept_name = resData.department.dept_name;

          this.showLoadingOverlay(false);
        })
        .catch((error) => {
          if (error.response.status == 404) {
            this.notFound = true;
          }
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    },

    async saveData() {
      this.$refs.form1.validate();

      if (!this.formValid) {
        this.showAlert("error", "Oops.. Please complete the form");
        return;
      }

      this.showLoadingOverlay(true);

      let formData = new FormData();

      formData.append('id', this.formData.id);
      formData.append('dept_code', this.formData.dept_code);
      formData.append('dept_name', this.formData.dept_name);

      await this.$axios
        .post("department/update", formData)
        .then((res) => {
          this.loading = false;
          this.showLoadingOverlay(false);

          var resData = res.data;

          if (resData.status == "success") {
            this.showAlert(resData.status, resData.message);

            this.goBack()
          }
        })
        .catch((error) => {
          this.loading = false;
          this.showLoadingOverlay(false);
          this.axiosErrorHandler(error);
        });
    }
  },

  mounted() {

    if (typeof this.$route.query.id == 'undefined') {
      this.redirect404();
    }

    this.formData.id = this.$route.query.id;

    this.getDetail(true);
  }
}
</script>